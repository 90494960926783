// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
  Box,
  Text,
  Button,
  Container,
  Spacer,
} from "@chakra-ui/react";
// assets
import backgroundImage from "assets/img/bg.jpg";
import backgroundImage1 from "assets/img/BackgroundCard1.png";
import logoChakra from "assets/img/cube.png";
//import logoChakra from "assets/img/ImageArchitect1.png";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,SupportIcon,
  WalletIcon,RocketIcon
} from "components/Icons/Icons.js";
import React from "react";
import {dashboardTableData,timelineData} from "variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import AppCards from "./components/AppCards";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";

export default function Dashboard() {
  const iconBoxInside = useColorModeValue("white", "white");

  return (
    <Flex flexDirection='column' alignItems="space-around" justifyContent="space-around" pt={{ base: "120px", md: "75px" }} mh="50px">
      <Box
        bgImage={`url(${backgroundImage})`}
        bgSize="cover"
        bgPosition="center"
        minHeight="200px" // Adjust this to your desired height
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        color="white" flexDirection='column' 
      >
        <Text fontSize="4xl" fontWeight="bold">
          Καλώς ήρθατε στα AgriTools
        </Text>
        <Text fontSize="lg">
          Ανακαλύψτε εργαλεία που έχουν σχεδιασθεί για τις ανάγκες των Ειδικών Υπηρεσιών του ΣΣ ΚΑΠ 2023-2027.
        </Text>
      </Box>
      <SimpleGrid justifyContent="center" alignItems="center" columns={{ sm: 1, md: 2, xl: 3 }} spacing='12px' my='10px'>
        <AppCards
          title={"Εργαλεία για συγκεκριμένες ανάγκες"} subtitle={"Ανάπτυξη εφαρμογών για έκτακτες ή μικρής κλίμακας ανάγκες"}
          icon={<WalletIcon h={"2rem"} w={"2rem"} color={iconBoxInside} />}
        />
        <AppCards
          title={"Ανοιχτά δεδομένα"} subtitle={"Αξιοποίηση ανοιχτών δεδομένων και εφαρμογών δημοσίου"}
          icon={<SupportIcon h={"2rem"} w={"2rem"} color={iconBoxInside} />}
        />
        <AppCards
          title={"Διαλειτουργικότητα"} subtitle={"Συμπληρωματική αξία στη διαλειτουργικότητα συστημάτων"}
          icon={<DocumentIcon h={"2rem"} w={"2rem"} color={iconBoxInside} />}
        />
      </SimpleGrid>
      <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my='10px'
        gap='24px'>
        <BuiltByDevelopers
          title={"Γιατί δημιουργήθηκε η σελίδα;"}
          name={"Γιατί δημιουργήθηκε η σελίδα;"}
          description={
            "Η παρούσα σελίδα δημιουργήθηκε για να καλύψει στοχευμένες ανάγκες της Ειδικής Υπηρεσίας Διαχείρισης ΣΣ ΚΑΠ, των υπόλοιπων Ειδικών Υπηρεσιών Εφαρμογής και Ενδιάμεσων Φορέων για Μέτρα του ΠΑΑ 2014-2022 και παρεμβάσεις της ΚΑΠ 2023-2027."
          }
          image={<Image src={logoChakra} alt='chakra image'/>}
        />
        <WorkWithTheRockets
          backgroundImage={backgroundImage1}
          title={"Προτάσεις για νέες εφαρμογές"}
          description={"Μπορείτε να προτείνετε νέες εφαρμογές απλά επικοινωνώντας με τη Μονάδα Χρηματοοικονομικών Ροών και Πληροφοριακών Συστημάτων της ΕΥΔ ΣΣ ΚΑΠ."}
        />
      </Grid>
    </Flex>
  );
}
