// Chakra imports
import { Box, Flex, Grid, Icon, Button, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import React, {useState} from "react";
import { FaPaypal, FaWallet } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import XMLBox from "./components/XMLBox";
import UploadEIF from "./components/UploadEIF";
import UploadApplications from "./components/UploadApplications";
import {useCookies} from 'react-cookie';

function Billing() {
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const textColor = useColorModeValue("gray.700", "white");
  const [evaluated, setEvaluated] = useState();
  const [signed, setSigned] = useState();
  const [disbursed, setDisbursed] = useState();
  const [eif1, setEIF1] = useState();
  const [eif2, setEIF2] = useState();
  const [applicationsData, setApplicationsData] = useState();
  const [myerror, setMyError] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(['creds']);
  console.log(cookies);
  if("creds" in cookies){
    var url = process.env.REACT_APP_PRODUCTION_URL+"/createFundsXML?username="+cookies.creds.username+"&userid="+cookies.creds.userid+"&eif1="+eif1+"&eif2="+eif2+"&funds="+applicationsData;
  } else {
    var url = process.env.REACT_APP_PRODUCTION_URL+"/createFundsXML?username=&userid=&eif1="+eif1+"&eif2="+eif2+"&funds="+applicationsData;
  }
  const fetchData = () => {
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.myerror==="1"){
          setMyError("Δεν έχετε δικαιώματα να εκτελέσετε αυτή τη λειτουργία.");
        } else if (data.myerror) {
          setMyError(data.error);
        }
        else{
          setEvaluated(data.evaluated);
          setSigned(data.signed);
          setDisbursed(data.disbursed);
        }
      })
      .catch(error => {console.log("test");});
  }
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Text color={textColor} fontSize='s' >Αφορά τη Μονάδα Χρηματοδοτικών Εργαλείων και Εργαλείων Διαχείρισης Κινδύνου</Text>
      <Grid templateColumns={{ sm: "1fr", lg: "1fr 1fr" }} templateRows='1fr' spacing='24px'>
        <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
          <Box margin='20px' display='flex'>
            <UploadEIF title="Αναφορά EIF" setEIF1={setEIF1} setEIF2={setEIF2}/>
            <UploadApplications title="Αιτήσεις δανείων" setApplicationsData={setApplicationsData}/>
            <Flex justify='center' align='center' minHeight='60px' w='100%'>
              <Button bg={bgButton} color='white' fontSize='l' variant='no-hover' onClick={fetchData}>
                Παραγωγή αρχείων XML
              </Button>
            </Flex>
          </Box>
        </Card>
        <XMLBox title={"XML αρχεία"} xml1={evaluated} xml2={signed} xml3={disbursed} myerror={myerror}/>
      </Grid>
    </Flex>   
  );
}

export default Billing;
