import React, { Component } from 'react';
import {
  Box,Flex,
  Button,
  Text,useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {read, utils} from 'xlsx';

class UploadEIF extends Component {
  constructor(props){
    super(props);
    this.state={
      isOpen: false,
      dataLoaded: false,
      isFormInvalid: false,
      rows: null,
      cols: null
    }
    this.fileHandler = this.fileHandler.bind(this);
    this.toggle = this.toggle.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.fileInput = React.createRef();
  }

  renderFile = (fileObj) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname1 = wb.SheetNames[0];
      const ws1 = wb.Sheets[wsname1];
      const data1 = utils.sheet_to_json(ws1, { header: 1 });
      this.props.setEIF1(JSON.stringify(data1));
      /* Get second worksheet */
      const wsname2 = wb.SheetNames[1];
      const ws2 = wb.Sheets[wsname2];
      const data2 = utils.sheet_to_json(ws2, { header: 1 });
      this.props.setEIF2(JSON.stringify(data2));
    };
    if (rABS) reader.readAsBinaryString(fileObj);
    else reader.readAsArrayBuffer(fileObj);
  }

  fileHandler = (event) => {    
    if(event.target.files.length){
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;

      
      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false
        });
        this.renderFile(fileObj)
      }    
      else{
        this.setState({
          isFormInvalid: true,
          uploadedFileName: "Πρέπει να ανεβάσετε αρχείο xlsx"
        })
      }
    }               
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  openFileBrowser = () => {
    this.fileInput.current.click();
  }

  render() {
    return (
      <Flex justify='space-between' align='center' flexDirection="column" minHeight='60px' w='100%'>
        <Text fontSize='lg' color="white" fontWeight='bold'>
          {this.props.title}
        </Text>
        <Button my="2.5rem" color="info" style={{color: "white", zIndex: 0}} onClick={this.openFileBrowser.bind(this)}><i className="cui-file"></i> Browse&hellip;</Button>
        <Text>{this.state.uploadedFileName}</Text>
        <input type="file" hidden onChange={this.fileHandler.bind(this)} ref={this.fileInput} onClick={(event)=> { event.target.value = null }} style={{"padding":"10px","marginLeft":"10px"}} />
      </Flex>
    );
  }
}

export default UploadEIF;