// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Button,
  Text,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure
} from "@chakra-ui/react";
// Assets
import React, { useState, useEffect, useCallback, useRef } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import XMLWindow from "./components/XMLWindow";
import UploadApplications from "./components/UploadApplications";
import ComponentPrint from './components/ComponentPrint';
import { useCookies } from 'react-cookie';
import { saveAs } from 'file-saver';
import { pdf, Page, Text as PDFText, Font, View, Document, StyleSheet } from '@react-pdf/renderer';
import Header from './components/Header';
import DataTable, { createTheme } from 'react-data-table-component';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";
import * as XLSX from "xlsx";
import Policy from 'policy.pdf';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf' },
    { src: 'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf', fontStyle: 'bold' }
  ]
});

function Sampling() {

  const [fetchSampleDataTrigger, setFetchSampleDataTrigger] = useState(0);
  const [fetchSamplingDataTrigger, setFetchSamplingDataTrigger] = useState(0);

  useEffect(() => {
    if (selectedSample !== undefined) {
      if (type === "pdf") {
        generatePdfDocument("sample.pdf", <MyHistorySample />);
      } else {
        ExportXlsx(selectedSample, "Sample");
      }
    }
  }, [selectedSample, fetchSampleDataTrigger]);

  useEffect(() => {
    if (sampling !== undefined) {
      generatePdfDocument("sample.pdf", <MyDocument />);
    }
  }, [sampling, fetchSamplingDataTrigger]);

  createTheme('dark', { background: 'transparent' });

  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const columns = [
    { name: 'Ημερομηνία', selector: row => row.date, sortable: true },
    { name: 'Αριθμός αιτημάτων πληρωμής', selector: row => row.opsaa, sortable: true },
    {
      cell: (row) => <button style={{ borderColor: bgButton, borderWidth: 1, borderRadius: 5, padding: 5 }} onClick={(e) => fetchSampleData(e, row.date, "pdf")}>PDF</button>,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button style={{ borderColor: bgButton, borderWidth: 1, borderRadius: 5, padding: 5 }} onClick={(e) => fetchSampleData(e, row.date, "xls")}>XLS</button>,
      allowOverflow: true,
      button: true,
    },
  ];

  const data = [{ id: 1, date: '', opsaa: '', }]
  const textColor = useColorModeValue("gray.700", "white");
  const [history, setHistory] = useState(data);
  const [sampling, setSampling] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [selectedSample, setSelectedSample] = useState();
  const [applicationsData, setApplicationsData] = useState();
  const [myerror, setMyError] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(['creds']);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState();
  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    image: {
      marginBottom: 10,
    },
    leftColumn: {
      flexDirection: 'column',
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
      },
      '@media orientation: landscape': {
        width: 200,
      },
    },
    footer: {
      width: '100%',
      fontSize: 6,
      fontFamily: 'OpenSans',
      textAlign: 'center',
      justifyContent: 'center', alignItems: 'center',
      paddingTop: 5,
      borderTopWidth: 0.5,
      borderTopColor: 'gray',
      borderTopStyle: 'dashed',
      '@media orientation: landscape': {
        marginTop: 10,
      },
    },
    tableStyle: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 2
    },
    tableRow: {
      margin: "auto",
      width: "100%",
      flexDirection: "row"
    },
    tableCol: {
      width: "15%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      fontFamily: 'OpenSans',
      margin: "auto",
      marginVertical: 5,
      fontSize: 10,
      color: bgButton
    },
    tableHeader: {
      fontFamily: 'OpenSans',
      fontStyle: 'bold',
      margin: "auto",
      marginTop: 5,
      fontSize: 10
    }
  });

  let url, historyUrl;
  if ("creds" in cookies) {
    url = process.env.REACT_APP_PRODUCTION_URL + "/createSampleM193?username=" + cookies.creds.username + "&userid=" + cookies.creds.userid+"&data="+applicationsData;
    historyUrl = process.env.REACT_APP_PRODUCTION_URL + "/getHistorySampleM193?username=" + cookies.creds.username + "&userid=" + cookies.creds.userid;
  } else {
    url = process.env.REACT_APP_PRODUCTION_URL + "/createSampleM193?username=&userid=";
    historyUrl = process.env.REACT_APP_PRODUCTION_URL + "/getHistorySampleM193?username=&userid=";
  }

  const generatePdfDocument = async (fileName, pdfDocumentComponent) => {
    const blob = await pdf(pdfDocumentComponent).toBlob();
    saveAs(blob, fileName);
  };

  const ExportXlsx = (a, fileName) => {
    const newData = a.map((item) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          newItem[key] = item[key].join(", ");
        }
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, fileName + ".xlsx");
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  // Create Document Component
  const MyDocument = () => (
    <Document title="Δείγμα 19.3" author={cookies.creds.username} creator={cookies.creds.username} producer={cookies.creds.username}>
      <Page size="A4" orientation="landscape" wrap="false">
        <Header username={cookies.creds.username} date={formatDate(new Date())} />
        <View style={styles.container}>
          <View style={styles.tableStyle}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Κωδικός ΟΠΣΑΑ</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Επωνυμία</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Τελική πληρωμή</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Αιτούμενο ποσό πληρωμής</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Επιλέχθηκε στο δείγμα</PDFText>
              </View>
            </View>
            {sampling.map((record) => (
              <View style={styles.tableRow} key={record.opsaa}>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.opsaa}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.name}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.final}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.amount}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.selectedsample}</PDFText>
                </View>
              </View>
            ))}
          </View>
        </View>
        <PDFText style={styles.tableCell}>Η παρούσα αναφορά αποτυπώνει το δείγμα που εξήχθη για αιτήσεις πληρωμής του 19.3 με τυχαία δειγματοληψία.</PDFText>
        <PDFText style={styles.footer} fixed>ΕΥΔ ΣΣ ΚΑΠ, Μονάδα Χρηματοοικονομικών Ροών και Πληροφοριακών Συστημάτων</PDFText>
      </Page>
    </Document>
  );

  // Create Document Component
  const MyHistorySample = () => (
    <Document title="Δείγμα 19.3" author={cookies.creds.username} creator={cookies.creds.username} producer={cookies.creds.username}>
      <Page size="A4" orientation="landscape" wrap="false">
        <Header username={selectedSample[0]['username']} date={selectedSample[0]['date']} />
        <View style={styles.container}>
          <View style={styles.tableStyle}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Κωδικός ΟΠΣΑΑ</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Επωνυμία</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Τελική πληρωμή</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Αιτούμενο ποσό πληρωμής</PDFText>
              </View>
              <View style={styles.tableCol}>
                <PDFText style={styles.tableHeader}>Επιλέχθηκε στο δείγμα</PDFText>
              </View>
            </View>
            {selectedSample.map((record) => (
              <View style={styles.tableRow} key={record.opsaa}>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.opsaa}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.name}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.final}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.amount}</PDFText>
                </View>
                <View style={styles.tableCol}>
                  <PDFText style={styles.tableCell}>{record.selectedsample}</PDFText>
                </View>
              </View>
            ))}
          </View>
        </View>
        <PDFText style={styles.tableCell}>Η παρούσα αναφορά αποτυπώνει το δείγμα που εξήχθη στις {selectedSample[0].date} για αιτήσεις πληρωμής του 19.3.</PDFText>
        <PDFText style={styles.footer} fixed>ΕΥΔ ΣΣ ΚΑΠ, Μονάδα Χρηματοοικονομικών Ροών και Πληροφοριακών Συστημάτων</PDFText>
      </Page>
    </Document>
  );

  const fetchData = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("sdsdfsdfds",applicationsData)
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        setIsLoading(false);
        alert(data.myerror);
      } else {
        var mysample = data.sample;
        for (let i = 0; i < mysample.length; i++) {
          if (mysample[i]['final'] === 0) {
            mysample[i]['final'] = 'Μερική';
          } else {
            mysample[i]['final'] = 'Τελική';
          }
        }
        await setSampling(mysample);
        fetchHistoryData();
        setFetchSamplingDataTrigger((prev) => prev + 1); // Update the trigger
        alert("Το δείγμα έχει παραχθεί και μπορείτε να το αναζητήσετε στον παρακάτω πίνακα.");
      }
    } catch (error) {
      console.log("test", error);
      setIsLoading(false);
      alert(error);
    }
    onClose();
  }, [applicationsData, cookies]);

  const fetchHistoryData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(historyUrl);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        setIsLoading(false);
        alert(data.myerror);
      } else {
        setIsLoading(false);
        await setHistory(data.history);
      }
    } catch (error) { console.log("test", error); setIsLoading(false); }
  }

  const fetchSampleData = useCallback(async (e, date, mytype) => {
    e.preventDefault();
    setIsLoading(true);
    let getSampleUrl;
    if ("creds" in cookies) {
      getSampleUrl = process.env.REACT_APP_PRODUCTION_URL + "/getSampleM193?username=" + cookies.creds.username + "&userid=" + cookies.creds.userid + "&date=" + date;
    } else {
      getSampleUrl = process.env.REACT_APP_PRODUCTION_URL + "/getSampleM193?username=&userid=&date=" + date;
    }
    try {
      const response = await fetch(getSampleUrl);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        setIsLoading(false);
        alert(data.myerror);
      } else {
        var mysample = data.sample;
        for (let i = 0; i < mysample.length; i++) {
          if (mysample[i]['final'] === 0) {
            mysample[i]['final'] = 'Μερική';
          } else {
            mysample[i]['final'] = 'Τελική';
          }
        }
        await setSelectedSample(mysample);
        await setType(mytype);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("test", error);
      setIsLoading(false);
    }
    setFetchSampleDataTrigger((prev) => prev + 1); // Update the trigger
  }, [cookies]);
  console.log(history,"history")

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }} >
      <Grid templateColumns={{ sm: "1fr", lg: "1fr 1fr" }} templateRows='1fr' spacing='24px'>
        <Card my={{ lg: "24px" }} me={{ lg: "24px" }} w="150%">
          <Box margin='20px' display='flex'>
            <UploadApplications title="Δεδομένα για εξαγωγή δείγματος" setApplicationsData={setApplicationsData} textColor={textColor} bgButton={bgButton} />
            <Flex justify='center' my="1rem" align='center' flexDirection="column" minHeight='60px' w='100%'>
              <Button bg={bgButton} color='white' fontSize='l' variant='no-hover' onClick={onOpen} w='100%'>
                Εξαγωγή δείγματος
              </Button>
              <Button bg={bgButton} my="1rem" color='white' fontSize='l' variant='no-hover' onClick={fetchHistoryData} w='100%'>
                Ιστορικό δειγματοληψίας
              </Button>
            </Flex>
          </Box>
          <Text color={textColor} fontSize='s'>Η παρούσα εφαρμογή εξυπηρετεί την ανάγκη εξαγωγής δείγματος επί αιτημάτων πληρωμής του Υπομέτρου 19.3. Απαιτείται η επισύναψη ενός αρχείου με την προβλεπόμενη πληροφορία για την εκτέλεση της δειγματοληψίας. Επιπλέον, υπάρχει η δυνατότητα άντλησης προηγούμενων δειγμάτων βάσει ιστορικού εφαρμογής. Η εφαρμογή είναι προσβάσιμη μόνο από πιστοποιημένους χρήστες.</Text>
          <Flex justify='space-between' my="1rem" align='center' flexDirection="row" minHeight='60px' w='100%'>
            <a href={Policy} target="_blank" style={{ color: 'white', margin: '20px 0', fontWeight: 'bold', }}>Οδηγίες χρήσης</a>
            <a href={Policy} target="_blank" style={{ color: 'white', margin: '20px 0', fontWeight: 'bold', }}>Πολιτική Απορρήτου</a>
          </Flex>
          {isLoading && <LoadingSpinner />}
          {history[0]['opsaa']!='' && !isLoading && <DataTable columns={columns} data={history} pagination theme="dark" />}
        </Card>
      </Grid>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Εξαγωγή δείγματος
            </AlertDialogHeader>
            <AlertDialogBody>
              Είστε σίγουροι ότι θέλετε να προχωρήσετε στην εξαγωγή του δείγματος; Μετά την εκτέλεση, δεν ακυρώνεται το δείγμα.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Άκυρο
              </Button>
              <Button colorScheme='blue' onClick={fetchData} ml={3}>
                Εκτέλεση
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Sampling;
