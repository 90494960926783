// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import XmlDownload from "components/Tables/XmlDownload";
import React from "react";

const XMLBox = ({ title, xml1, xml2, xml3, myerror }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
      <Flex direction='column'>
        <CardHeader py='2px'>
          <Text color={textColor} fontSize='lg' fontWeight='bold'>
            {title}
          </Text>
        </CardHeader>
        <CardBody>
          <Flex direction='column' w='100%'>
            {xml1 && <XmlDownload title="Αξιολογήσεις" json={xml1}/>}
            {xml2 && <XmlDownload title="Συμβάσεις" json={xml2}/>}
            {xml3 && <XmlDownload title="Εκταμιεύσεις" json={xml3}/>}
            {myerror && <Text color={textColor} fontSize='lg' fontWeight='bold'>{myerror}</Text>}
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  );
};

export default XMLBox;
