// Chakra imports
import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,Text, Grid
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import React from "react";

const AppCards = ({ title, subtitle, amount, percentage, icon }) => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card minH='150px' w="100%">
      <CardBody>
        <Flex flexDirection='row' align='flex-start' justify='flex-start' w='100%'>
          <Stat me='auto'>
            <IconBox as='box' h={"5rem"} w={"5rem"} bg={iconTeal}>
              {icon}
            </IconBox>
          </Stat>
          <Grid flexDirection='column' my='10px' gap='10px' mx='5px'>
            <Text fontSize="lg" fontWeight="bold" color='gray.400'>
              {title}
            </Text>
            <Text fontSize="sm" color='gray.400'>
              {subtitle}
            </Text>
          </Grid>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default AppCards;
