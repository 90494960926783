// Chakra imports
import { Box, Flex, Grid, Icon, Button, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import React, {useState} from "react";
import { FaPaypal, FaWallet } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ShowResults from "./components/ShowResults";
import UploadApplications from "./components/UploadApplications";
import {useCookies} from 'react-cookie';

function Diavgeia() {
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const textColor = useColorModeValue("gray.700", "white");
  const [results, setResults] = useState();
  const [applicationsData, setApplicationsData] = useState();
  const [myerror, setMyError] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(['creds']);
  if("creds" in cookies){
    var url = process.env.REACT_APP_PRODUCTION_URL+"/searchDiavgeia?username="+cookies.creds.username+"&userid="+cookies.creds.userid+"&applicationsData="+applicationsData;
  } else {
    var url = process.env.REACT_APP_PRODUCTION_URL+"/searchDiavgeia?username=&userid=&applicationsData="+applicationsData;
  }
  console.log(url,"urruru")
  const fetchData = () => {
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.myerror) {
          setMyError(data.myerror);
          alert(data.myerror);
        }
        else{
          setResults(data.decisions);
        }
      })
      .catch(error => {console.log("test");});
  }
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "1fr 1fr" }} templateRows='1fr' spacing='24px'>
        <Box margin='24px'>
          <UploadApplications title="Κωδικοί ΟΠΣΑΑ" setApplicationsData={setApplicationsData} />
          <Card p='16px' mt='24px'>
            <CardHeader>
              <Flex justify='center' align='center' minHeight='60px' w='100%'>
                <Button bg={bgButton} color='white' fontSize='l' variant='no-hover' onClick={fetchData}>
                  Αναζήτηση αποφάσεων
                </Button>
              </Flex>
            </CardHeader>
          </Card>
        </Box>
        <ShowResults title={"Αποτελέσματα αναζήτησης στη ΔΙΑΥΓΕΙΑ"} xml1={results} myerror={myerror}/>
      </Grid>
      <Text color={textColor} fontSize='s' >Η παρούσα εφαρμογή εξυπηρετεί τη μαζική αναζήτηση αποφάσεων στη ΔΙΑΥΓΕΙΑ για ένα ή περισσότερα έργα με βασικό κριτήριο τον Κωδικό ΟΠΣΑΑ.</Text>
      <Text color={textColor} fontSize='s' >Απαιτείται η επισύναψη αρχείου xlsx με τους κωδικούς ΟΠΣΑΑ σε μία στήλη, κατά προτίμηση χωρίς όνομα στήλης.</Text>
      <Text color={textColor} fontSize='s' >Για κάθε κωδικό ΟΠΣΑΑ, επιστρέφονται τα αποτελέσματα αναζήτησης ή το λόγο αποτυχίας (πχ εγκυρότητα κωδικού, ανυπαρξία αποφάσεων κ.ο.κ.)</Text>
    </Flex>
  );
}

export default Diavgeia;