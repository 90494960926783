import React, { Component } from 'react';
import {
  Box,Flex,
  Button,
  Text,useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import exportFromJSON from 'export-from-json';

class XmlDownload extends Component {
  constructor(props){
    super(props);
    this.state={
      data:this.props.json,
      fileName: this.props.title,
      exportType: exportFromJSON.types.xml,
    }
  }

  download = () => {
    const data = this.props.json;
    const fileName = this.props.title;
    const exportType = 'csv';
    exportFromJSON({data, fileName, exportType})
  }


  render() {
    return (
      <Card p='16px'>
      <CardBody>
        <Flex
          direction={{ sm: "column", md: "row" }}
          align='center'
          w='100%'
          justify='space-around'
          py='0.5rem'>
          <Flex
            p='1rem'
            bg='transparent'
            borderRadius='15px'
            width='100%'
            border='1px solid'
            borderColor="black"
            align='center' justify='space-between'
            mb={{ sm: "24px", md: "0px" }}
            me={{ sm: "0px", md: "24px" }}>
            <Text fontSize='lg' color="white" fontWeight='bold'>{this.props.title}</Text>
            <Button color="info" style={{color: "white", zIndex: 0}} onClick={this.download}><i className="cui-file"></i> Κατέβασε</Button>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
    );
  }
}

export default XmlDownload;