// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,useDisclosure
} from "@chakra-ui/react";
import {Separator} from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, {useState} from "react";
import MicrosoftLogin, {logout} from "react-microsoft-login";
import {useCookies} from 'react-cookie';

function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

export default function Configurator(props) {
  const [msalInstance, onMsalInstanceChange] = useState();
  const [name,setName] = useState();
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);
  const [cookies, setCookie, removeCookie] = useCookies(['creds']);
  const colorMode = useColorMode(); // Use useColorMode hook to get the current color mode
  if (colorMode.colorMode === "light") {
    colorMode.toggleColorMode();
  }

  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();

  const loginHandler = async (err, data, msal) => {
    if (!err && data) {
      onMsalInstanceChange(msal);
      setName(data.displayName);
      setCookie('creds',JSON.stringify({"username":data.mail,"userid":data.id}));
    } else {
      alert(err);
    }
  };

  const logoutHandler = () => {
    msalInstance.logout();
    setName();
    removeCookie('creds');
  };

  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            {msalInstance && <Text fontSize="xl" fontWeight="bold" mt="16px">
              {name}
            </Text>}
            {msalInstance && <button onClick={logoutHandler} my="10px">Αποσύνδεση</button>}
            {!msalInstance && <MicrosoftLogin clientId={process.env.REACT_APP_MICROSOFT_LOGIN} authCallback={loginHandler} withUserData="true" useLocalStorageCache="true" buttonTheme="dark"/>}
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              Ρυθμίσεις
            </Text>
            <Text fontSize="md" mb="16px">
              Ελέγξτε τις ρυθμίσεις
            </Text>
            <Separator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              <Box>
                <Text fontSize="md" fontWeight="600">
                  Μορφή πλευρικής στήλης
                </Text>
                <Text fontSize="sm" mb="16px">
                  Επιλέξτε μεταξύ δύο μορφών πλευρικής στήλης.
                </Text>
                <Flex>
                  <Button
                    w="50%"
                    p="8px 32px"
                    me="8px"
                    colorScheme="teal"
                    borderColor="teal.300"
                    color="teal.300"
                    variant="outline"
                    fontSize="xs"
                    onClick={props.onTransparent}
                  >
                    Διαφανής
                  </Button>
                  <Button
                    type="submit"
                    bg="teal.300"
                    w="50%"
                    p="8px 32px"
                    mb={5}
                    _hover="teal.300"
                    color="white"
                    fontSize="xs"
                    onClick={props.onOpaque}
                  >
                    Αδιαφανής
                  </Button>
                </Flex>
              </Box>
              <Box display={fixedDisplay} justifyContent="space-between " mb="16px">
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Σταθερή θέση γραμμής πλοήγησης
                </Text>
                <Switch
                  colorScheme="teal"
                  isChecked={switched}
                  onChange={(event) => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Box>
              <Separator />
              
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

/*<Flex justifyContent="space-between" alignItems="center" mb="24px">
  <Text fontSize="md" fontWeight="600" mb="4px">
    Dark/Light
  </Text>
  <Button onClick={toggleColorMode}>
    Toggle {colorMode === "light" ? "Dark" : "Light"}
  </Button>
</Flex>*/


Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
