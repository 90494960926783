// import
import Dashboard from "views/Dashboard/Dashboard";
import Sampling from "views/Dashboard/Sampling";
import M16Sampling from "views/Dashboard/M16Sampling";
import Diavgeia from "views/Dashboard/Diavgeia";
import FI from "views/Dashboard/FI";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Πίνακας Ελέγχου",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/fi",
    name: "Χρηματοδοτικά εργαλεία",
    icon: <CreditIcon color="inherit" />,
    component: FI,
    layout: "/admin",
  },
  {
    path: "/sampling",
    name: "Δειγματοληψία Μ19.3",
    icon: <DocumentIcon color="inherit" />,
    component: Sampling,
    layout: "/admin",
  },
  {
    path: "/m16Sampling",
    name: "Δειγματοληψία Μ16",
    icon: <RocketIcon color="inherit" />,
    component: M16Sampling,
    layout: "/admin",
  },
  {
    path: "/diavgeia",
    name: "Αναζήτηση ΔΙΑΥΓΕΙΑ",
    icon: <SupportIcon color="inherit" />,
    component: Diavgeia,
    layout: "/admin",
  }
];
export default dashRoutes;